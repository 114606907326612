import Layout from '../components/layout'
import Seo from '../components/seo'
import {Link} from 'gatsby'
import React from 'react'
import useStations from '../utils/hooks/useStations'

const BuyWeatherStationPage = () => {
  const {station} = useStations()
  return (
    <Layout station={station}>
      <Seo title="Buy a Weather Station" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Buy a Weather Station</h1>

            <p className="intro">
              A modest investment in a weather station will mean consistent
              savings from enhanced IPM and crop management decision-making.
            </p>

            <p>
              NEWA collaborates with two weather station vendors to provide
              hardware,{' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset Data Loggers
              </Link>{' '}
              and and <Link to="https://kestrelmet.com">KestrelMet</Link>. NEWA
              is an independent operator and does not receive any commission or
              payment of any sort from the sale of weather station hardware from
              these vendors.
            </p>

            <h2>Am I in the NEWA network?</h2>

            <p>
              Twenty US states are in the NEWA network. Your membership is
              covered in full if you live in any of these states so visit our{' '}
              <Link to="/partners">Partners</Link> page to find out more about
              your membership status.
            </p>

            <p>
              Not in the NEWA network? Individuals in non-member US states can
              still join NEWA for an annual membership fee of $290 for the first
              weather station. Additional machines can also be added at a
              pro-rated cost of $260 each. These fees are paid after your
              station is connected to NEWA.
            </p>

            <p>
              Want your state to join NEWA? Visit our{' '}
              <Link to="/become-partner">Become a Partner</Link> page for more
              information about state-level NEWA membership.
            </p>

            <hr></hr>
            <h2>Buy a weather station</h2>

            <p>
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset Data Loggers
              </Link>{' '}
              and and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link>{' '}
              provision weather stations pre-configured for full compatibility
              with NEWA’s agricultural production tools. The NEWA network does
              not accept data from other brands or custom-built devices.
            </p>

            <p>
              Contact the vendors to find a weather station that fits your
              business’ needs. Knowledgeable sales and support staff are
              available to answer your questions. Speak with them about
              maintenance requirements, sensor replacement, and expected
              lifespan. Also, review the NEWA{' '}
              <Link to="/maintenance-guide">Maintenance Guide</Link> page for
              additional information before making your purchase.
            </p>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h2>
                <a
                  href="https://www.onsetcomp.com/corporate/partners/newa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Onset Data Loggers
                </a>
              </h2>

              <p>
                Greg Greenberg, VP of Global Sales<br></br>
                401-396-6222<br></br>
                <a href="mailto:ggreenberg@onsetcomp.com">
                  ggreenberg@onsetcomp.com
                </a>
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h2>
                <a
                  href="https://kestrelmet.com/kestrelmet-6000-ag-weather-station"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KestrelMet
                </a>
              </h2>

              <p>
                Eric Rollins, Business Development Manager<br></br>
                207-266-8465<br></br>
                <a href="mailto:bluscombe@rainwise.com">
                  eric.rollins@rainwise.com
                </a>
              </p>
            </div>

            <hr></hr>
            <h2>Weather station placement and activation</h2>

            <p>
              Follow manufacturer guidelines for placement of your weather
              station to ensure accurate and unobstructed data collection. Also,
              read the NEWA <Link to="/placement-guide">Placement Guide</Link>{' '}
              page for tips on siting your weather station. Connect the weather
              station to your vendor’s server and verify your machine is
              successfully reporting. and{' '}
              <Link to="https://kestrelmet.com">KestrelMet</Link> uses
              KestrelNet (
              <a
                href="https://kestrelnet.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://kestrelmet.net
              </a>
              ) and{' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset Data Loggers
              </Link>{' '}
              uses HOBOlink (
              <a
                href="https://hobolink.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://hobolink.com
              </a>
              ).
            </p>

            <h2>Connect with NEWA</h2>

            <p>
              Connect your weather station to the NEWA network after linking
              with your vendor’s online platform. Contact the{' '}
              <a href="mailto:support@newa.zendesk.com">NEWA Help Desk</a> to
              start this final step. Help Desk staff will confirm your request
              and generate a work ticket to onboard your new machine. You will
              receive final confirmation when your weather station has been
              added to NEWA.
            </p>

            <h2>Learn more</h2>

            <p>
              Learn about the capability of your weather station and how NEWA
              uses the weather variables in the NEWA Weather Tools and NEWA Crop
              & IPM Tools on the{' '}
              <Link to="/about-weather-stations">About Weather Stations</Link>{' '}
              Page.
            </p>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated 23 August 2021 by D. Olmstead</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{' '}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BuyWeatherStationPage
